// Build information, automatically generated by `ng-info`
const build = {
    version: "21.0.0-SNAPSHOT",
    timestamp: "Fri Sep 13 2024 11:07:37 GMT+0200 (Central European Summer Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "1656da",
        fullHash: "1656da23a06a1881c6ee684a533723da84e29eb5"
    }
};

export default build;