import { CageProdRecord } from 'app/shared/remote-services/dtos/cage.dto'
import { ColumnDto } from 'app/shared/remote-services/dtos/column.dto'
import { ColumnPropertiesDto } from 'app/shared/remote-services/dtos/columnProperties.dto'
import { DailyReportDto } from 'app/shared/remote-services/dtos/dailyReport.dto'
import { AsyncActionFactory } from 'app/shared/utils/redux/async.actions'
import { EntitiesActionFactory } from 'app/shared/utils/redux/entities/entities.actions'
import { ListActionFactory } from 'app/shared/utils/redux/list/list.actions'
import { LoadableActionFactory } from 'app/shared/utils/redux/loadable/loadable.actions'
import { SyncActionFactory } from 'app/shared/utils/redux/sync.actions'
import { OrderPhasingSubTool } from '../components/jobsite-map-instructions-buttons/hf/order-phasing/order-phasing-toolbar.model'
import { ColumnsSelectionModel } from '../models/columns-selection.model'
import { TreeNode } from '../models/columns-type-tree.model'
import { EventModel } from '../models/event.model'
import { ToolsActionType } from '../models/action-type.enum'
import { InteractionMode } from '../models/interaction-mode.enum'
import {
  MapColumnsPopoverModel,
  MapSurveyLayerPopoverModel,
  MapSurveyPopoverModel,
} from '../models/map-popover.model'
import { MetricFilter } from '../models/metric-filter.model'
import { ParameterModel } from '../models/parameter.model'
import { StatsSummary } from '../models/stats.dto'
import { ZoneColorDto } from '../models/zone-color.dto'
import { JobsiteSummaryState, Mode } from './state'
import { ConcreteProdRecord } from '../../../shared/remote-services/dtos/concrete.dto'
import { DateRange } from '../../../shared/localized-date-time-picker/localized-date-range-picker.component'
import { ColumnLength } from '../services/jobsite-map/hf-feature-length-selection/length-selection.model'
import { JobsiteTab } from '../models/jobsite-tabs.model'
import {
  ExportSinglePdfPayload,
  GoToTabPayload,
  PopoverInputPayload,
  UpdateColumnDiscardValuePayload,
  UpdateColumnPayload,
  UpdateInstructionPayload,
} from '../models/jobsite-summary.model'
import { UUID } from '../../../shared/models/utils.type'

export class JobsiteSummaryActions {
  public static setMode = new SyncActionFactory<Mode>(
    '[JOBSITE SUMMARY] SET MODE',
  )
  public static setSelectedTab = new SyncActionFactory<JobsiteTab>(
    '[JOBSITE SUMMARY] SET SELECTED TAB',
  )
  public static setIsTabLoading = new SyncActionFactory<boolean>(
    '[JOBSITE SUMMARY] SET IS TAB LOADING',
  )
  public static goToTab = new SyncActionFactory<GoToTabPayload>(
    '[JOBSITE SUMMARY] GO TO LINK',
  )
  public static setInteractionMode = new SyncActionFactory<InteractionMode>(
    '[JOBSITE SUMMARY] SET INTERACTION MODE',
  )

  public static setHighlightColumn = new SyncActionFactory<string>(
    '[JOBSITE SUMMARY] SET HIGHLIGHT COLUMN',
  )

  public static setParameter = new SyncActionFactory<ParameterModel>(
    '[JOBSITE SUMMARY] SET PARAMETER',
  )

  public static set2dParameter = new SyncActionFactory<ParameterModel>(
    '[JOBSITE SUMMARY] HANDLE SET 2D PARAMETER',
  )

  public static provideNameFilters = new ListActionFactory<string>(
    '[JOBSITE SUMMARY] PROVIDE NAME FILTERS',
  )
  public static provideMetricFilters = new ListActionFactory<MetricFilter>(
    '[JOBSITE SUMMARY] PROVIDE METRIC FILTERS',
  )
  public static updateDateRangeFilter = new SyncActionFactory<DateRange>(
    '[JOBSITE SUMMARY] UPDATE DATE RANGE FILTER',
  )

  public static loadJobsiteColumns = new ListActionFactory<ColumnDto, void>(
    '[JOBSITE SUMMARY] LOAD COLUMNS',
  )
  public static provideEvents = new ListActionFactory<EventModel>(
    '[JOBSITE SUMMARY] EVENTS',
  )
  public static selectedColumnIds = new ListActionFactory<string>(
    '[JOBSITE SUMMARY] SELECT COLUMN IDS',
  )

  public static columnsSelections = new ListActionFactory<
    ColumnsSelectionModel,
    void
  >('[JOBSITE SUMMARY] COLUMN SELECTIONS')
  public static jobsiteDetails = new LoadableActionFactory<void, StatsSummary>(
    '[JOBSITE SUMMARY] JOBSITE DETAILS',
  )
  public static addColumnsSelection =
    new SyncActionFactory<ColumnsSelectionModel>(
      '[JOBSITE SUMMARY] ADD COLUMN SELECTION',
    )
  public static updateColumnsSelection =
    new SyncActionFactory<ColumnsSelectionModel>(
      '[JOBSITE SUMMARY] UPDATE COLUMN SELECTION',
    )
  public static updateInstructions =
    new SyncActionFactory<UpdateInstructionPayload>(
      '[JOBSITE SUMMARY] UPDATE INSTRUCTIONS',
    )

  public static enterSummary = new SyncActionFactory<void>(
    '[JOBSITE SUMMARY] ENTER',
  )
  public static leaveSummary = new SyncActionFactory<void>(
    '[JOBSITE SUMMARY] LEAVE',
  )

  public static clickOnColumn = new SyncActionFactory<{
    columnId: string
    path: string
  }>('[JOBSITE SUMMARY] CLICK ON COLUMN')

  public static openExportPdfDialog = new SyncActionFactory<{
    columnId: string
    path: string
  }>('[JOBSITE SUMMARY] OPEN EXPORT PDF DIALOG')

  public static openKSMenuDialog = new SyncActionFactory<void>(
    '[JOBSITE SUMMARY] OPEN KS MENU DIALOG',
  )

  public static exportDataVerticalityCsv = new SyncActionFactory<UUID>(
    '[JOBSITE SUMMARY] EXPORT DATA VERTICALITY CSV',
  )

  public static exportPdfSingleColumn =
    new SyncActionFactory<ExportSinglePdfPayload>(
      '[JOBSITE SUMMARY] EXPORT SINGLE PDF',
    )

  public static exportCsvSingleColumn = new SyncActionFactory<{
    columnId: string
  }>('[JOBSITE SUMMARY] EXPORT SINGLE CSV')

  public static updateColumn = new AsyncActionFactory<UpdateColumnPayload>(
    '[JOBSITE SUMMARY] UPDATE A COLUMN',
  )

  public static updateColumnDiscardValue =
    new AsyncActionFactory<UpdateColumnDiscardValuePayload>(
      '[JOBSITE SUMMARY] UPDATE COLUMN DISCARD VALUE',
    )

  public static updateMultiColumns = new SyncActionFactory<ColumnDto[]>(
    '[JOBSITE SUMMARY] UPDATE MULTI COLUMNs',
  )

  public static importCSVColumns = new SyncActionFactory<ColumnPropertiesDto[]>(
    '[JOBSITE SUMMARY] IMPORT CSV COLUMNS',
  )
  public static addColumn = new SyncActionFactory<ColumnDto>(
    '[JOBSITE SUMMARY] ADD A COLUMN',
  )
  public static removeColumns = new SyncActionFactory<string[]>(
    '[JOBSITE SUMMARY] REMOVE COLUMNS',
  )
  public static exportCsv = new SyncActionFactory<void>(
    '[JOBSITE SUMMARY] EXPORT CSV',
  )
  public static exportJobsiteCsv = new SyncActionFactory<void>(
    '[JOBSITE SUMMARY] EXPORT JOBSITE CSV',
  )

  public static loadTypeTree = new ListActionFactory<TreeNode>(
    '[JOBSITE SUMMARY] GET TYPE TREE',
  )
  public static toggleTypeTree = new SyncActionFactory<boolean>(
    '[JOBSITE SUMMARY] TOGGLE TYPE TREE',
  )

  public static setMapPopoverContent = new SyncActionFactory<
    (
      | MapColumnsPopoverModel
      | MapSurveyPopoverModel
      | MapSurveyLayerPopoverModel
    )[]
  >('[JOBSITE SUMMARY] SET MAP POPOVER CONTENT')
  public static setPopoverPosition = new SyncActionFactory<
    JobsiteSummaryState['popoverPosition']
  >('[JOBSITE SUMMARY] SET MAP POPOVER POSITION')
  public static updateMapPopoverContent =
    new SyncActionFactory<PopoverInputPayload>(
      '[JOBSITE SUMMARY] UPDATE POPOVER BY PILE ID',
    )
  public static loadColumnsProd = new LoadableActionFactory<
    Record<string, DailyReportDto>
  >('[JOBSITE SUMMARY] LOAD COLUMNS PROD VALUES')

  public static setNullCoordinateColumnIds = new ListActionFactory<string>(
    '[JOBSITE SUMMARY] GET COLUMN IDS WITH NULL COORDINATE',
  )
  public static doneColumnIds = new ListActionFactory<string>(
    '[JOBSITE SUMMARY] DONE COLUMN IDS',
  )

  public static zonesColor = new ListActionFactory<ZoneColorDto, void>(
    '[JOBSITE SUMMARY] ZONES COLOR',
  )
  public static updateZoneColor = new SyncActionFactory<{
    color: string
    zone: string
  }>('[JOBSITE SUMMARY] UPDATE ZONES COLOR')

  public static setColumnIdsToFit = new ListActionFactory<string>(
    '[JOBSITE SUMMARY] FIT MAP TO SELECTED PILES',
  )

  public static setColumnColorMode = new SyncActionFactory<
    JobsiteSummaryState['columnColorMode']
  >('[JOBSITE SUMMARY] SET COLUMN COLOR MODE')

  public static updateHfEditEnabled = new SyncActionFactory<boolean>(
    '[JOBSITE SUMMARY] UPDATE HF EDIT MODE ENABLED OR NOT',
  )

  public static changeSelectedTool = new SyncActionFactory<ToolsActionType>(
    '[JOBSITE SUMMARY] CHANGE SELECTED TOOL',
  )

  public static selectOrderPhasingSubTool =
    new SyncActionFactory<OrderPhasingSubTool>(
      '[JOBSITE SUMMARY] SELECT ORDER PHASING SUB TOOL',
    )

  public static setProdEventSummaries = new SyncActionFactory<
    JobsiteSummaryState['prodEventSummaries']
  >('[JOBSITE SUMMARY] SET PROD EVENT SUMMARIES')

  public static setColorizeByDay = new SyncActionFactory<
    JobsiteSummaryState['colorizeByDayEnabled']
  >('[JOBSITE SUMMARY] SET COLORIZE BY DAY')

  public static rigEnabledPathEntitiesActions = new EntitiesActionFactory<{
    key: string
    value: boolean
  }>('[JOBSITE SUMMARY] RIG ENABLED PATH ENTITIES ACTIONS')

  public static updateAsbuiltWarningColorMode = new SyncActionFactory<boolean>(
    '[JOBSITE SUMMARY] UPDATE ASBUILT WARNING COLOR MODE',
  )

  public static cageProdRecordActions =
    new EntitiesActionFactory<CageProdRecord>(
      '[JOBSITE SUMMARY] CAGE_PROD_RECORD_ACTIONS',
    )

  public static concreteProdRecordActions =
    new EntitiesActionFactory<ConcreteProdRecord>(
      '[JOBSITE SUMMARY] CONCRETE_PROD_RECORD_ACTIONS',
    )

  public static setToolNbSelectedFeatures = new SyncActionFactory<
    JobsiteSummaryState['toolNbSelectedFeatures']
  >('[JOBSITE SUMMARY] SET TOOL NB SELECTED FEATURES')

  public static updateColumnsLengths = new SyncActionFactory<ColumnLength[]>(
    '[JOBSITE SUMMARY] UPDATE COLUMNS LENGTHS ',
  )

  public static setWarnMessageKey = new SyncActionFactory<string>(
    '[JOBSITE SUMMARY] SET WARN MESSAGE KEY',
  )

  public static setDisplayWarnMessage = new SyncActionFactory<boolean>(
    '[JOBSITE SUMMARY] SET DISPLAY WARN MESSAGE',
  )

  public static initWarnMessage = new SyncActionFactory<string>(
    '[JOBSITE SUMMARY] INIT WARN MESSAGE',
  )

  public static setRigSliderValue = new SyncActionFactory<
    JobsiteSummaryState['rigSliderValue']
  >('[JOBSITE SUMMARY] SET RIG SLIDER VALUE')

  public static setRigSliderHighValue = new SyncActionFactory<
    JobsiteSummaryState['rigSliderHighValue']
  >('[JOBSITE SUMMARY] SET RIG SLIDER HIGH VALUE')

  public static setShowRangeSlider = new SyncActionFactory<
    JobsiteSummaryState['showRangeSlider']
  >('[JOBSITE SUMMARY] SET SHOW RANGE SLIDER')

  public static goToChartMaker = new SyncActionFactory<string>(
    '[JOBSITE SUMMARY] GO TO CHART MAKER',
  )

  public static duplicatePileOpenModal = new SyncActionFactory<{
    columnId: string
    polygon: string
    newCoords: number[]
  }>('[JOBSITE SUMMARY] DUPLICATE PILE OPEN MODAL')

  public static duplicatePileToPileModal = new SyncActionFactory<{
    source: string
    to: string[]
  }>('[JOBSITE SUMMARY] DUPLICATE PILE TO PILE MODAL')

  public static duplicatePileToPile = new SyncActionFactory<{
    source: ColumnDto
    to: ColumnDto
  }>('[JOBSITE SUMMARY] DUPLICATE PILE TO PILE')

  public static resetDuplicateServices = new SyncActionFactory<void>(
    '[JOBSITE SUMMARY] RESET DUPLICATE SERVICES',
  )

  public static openImportCSVColumnsModal = new SyncActionFactory(
    '[JOBSITE SUMMARY] OPEN IMPORT CSV COLUMNS MODAL',
  )
  public static updateMapFocusCenterValue = new SyncActionFactory<
    number[] | null
  >('[JOBSITE SUMMARY] UPDATE MAP FOCUS CENTER')
}
